import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";
import styles from "./stories.module.css";
import Layout from "../components/layout";
import StoryPreview from "../components/story-preview";

class StoryIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const stories = get(this, "props.data.allContentfulStory.edges");

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={siteTitle} />
          {/* <div className={styles.hero}>Job</div> */}
          <div className="wrapper">
            <h2 className="section-headline">Job Board</h2>
            <ul className={styles.jobList}>
              {stories.map(({ node }) => {
                return (
                  <li key={node.id}>
                    <StoryPreview story={node} />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Layout>
    );
  }
}

export default StoryIndex;

export const pageQuery = graphql`
  query StoryIndexQuery {
    allContentfulStory(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          shortBio {
            childMarkdownRemark {
              html
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
          countryOfOrigin {
            name
            shortBio {
              childMarkdownRemark {
                html
              }
            }
            flag {
              fluid(maxWidth: 100, background: "rgb:ffffff") {
                ...GatsbyContentfulFluid
              }
              fixed(width: 100, background: "rgb:ffffff") {
                ...GatsbyContentfulFixed
              }
            }
          }
          credit {
            name
            url
          }
        }
      }
    }
  }
`;
